@import './config/colors-rainha.scss';

$radius : 5px;

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin redes-over {
  &.facebook:hover{
    background-color: #3b5898;
  }
  &.instagram:hover{
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
  }
}

@mixin disable-text-select {
  -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
}

body{
  background-color: $bgBody; 
  a{
    color: $txtCorBody;
    &:hover{
      color: inherit;
      text-decoration: none;
    }
  }
  #js-app{
    min-height: 100%;
    background-color: $corF4;
    .adm{
      height: 100%;
    }
  }
}

#FormFinanciamento{
  width: 100%;
  margin: auto;
}
.tel-fixo{
  font-size: 1.3em;
  :hover{
    color: $corFixoOver;
  }
}
.form-search{
  padding: 2em 2em 1em;
  position: relative;
  margin: -4em auto 0 auto;
  border-radius: $radius;
  background-color: $corFF;
  border-bottom: 5px solid $cor00;
  width: 90%;
  z-index: 99;
}

.btn-dark{
  border-radius: 0;
  border-color: $bgFooter;
  background-color: $bgFooter;
  &:hover{
    border-color: $corS01;
    background-color: $corS01;
  }
}

.navbar{
  background-color: $bgNav;
  &-nav{
    .nav-link{
      color: $txtCorNav!important;
      &:hover{
        color: $txtCorNavOver!important;
      }
    }
  }
  .dropdown-toggle{
    margin-right:20px;
    &::after{
      border-width: 0;
    }
    &::before{
     content: "\f232";
     font-family: "Font Awesome 5 Brands"; font-weight: 400;
     margin-right: 0.5em;
    }
  }
  .nav-link{
    margin-right: 10px;
  }
}
.bannerVideo{
  display: flex;
  max-height: 640px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
  align-items: center;
  video{
    width: 100%;
    // height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.dropdown-item{
  padding: .25rem 0.75rem;
  position: relative;
}

.dropdown-menu{
  padding:0;
}

.btn-success ~ .dropdown-menu > .dropdown-item{
  &:hover{
    color: $txtCorZap;
    background-color: $bgCorZap;
    border-color: $borderCorZap;
    &::after{
      content: "\f232";
      font-family: "Font Awesome 5 Brands"; font-weight: 400;
      top:1em;
      right: 0.75em; 
      position: absolute;
    }
  }
  &:active, &.active{
    color: $txtCorZap;
    background-color: $bgCorZap;
    border-color: $borderCorZap;
  }
}

.btn-info ~ .dropdown-menu > .dropdown-item{
  &:hover{
    color: $txtCorZap;
    background-color: $bgCorTel;
    border-color: $borderCorTel;
    &::after{
      content: "\f2a0";
      font-family: "Font Awesome 5 Free"; font-weight: 700;
      top:1em;
      right: 0.75em; 
      position: absolute;
    }
  }
  &:active, &.active{
    color: $txtCorZap;
    background-color: $bgCorTel;
    border-color: $borderCorTel;
  }
}

.phoneCard{
  padding: 5px;
    line-height: 0.9em;
  &-title{
    display: block;
    font: {
      size: 0.9em;
      weight: 600;
    }
  }
  &-subtitle{
    font-size: 0.8em;
  }
}

.stock{
  .MuiButtonBase-root{margin: 0!important;}
  &-image{
    width: 100%;
    background-size: cover;
    background-position: center;
  }
  &-img4x3{
    padding-bottom: 75%;
  }
  &-title{
    font-size: 21px;
    position: relative;
    background: $corGradMid;
    background: linear-gradient(90deg, $corGradStart 0%, $corGradMid 50%, $corGradEnd 100%);
    padding: .6em 0;
    width: 95%;
    margin:0 auto 1.5em auto;
  }
  &-card{
    max-width: 300px;
    margin: 1.5em auto;
    box-shadow: 3px 3px 8px $cor0020;
    @include disable-text-select;
    p{
      margin: 0;
      @include ellipsis;
    }
    .no-ellipsis{
      text-overflow: clip;
    }
    &-dados{
      background-color: $corFF;
      padding: 20px;
    }
    &-title{
      font: {
        size: 18px;
        weight: 700;
      }
    }
    &-subtitle{
      font: {
        size: 14px;
        weight: 500;
      }
        color: $cor77;
        opacity: 0.6;
    }
    &-text{
      font: {
        size: 13px;
        weight: 500;
      }
      line-height: 2em;
      color: $cor77;
      input{
        font-size: 13px;
        color: $cor77;
      }
    }
    &-preco{
      //background-color: $corED;
      input, p span{
        display: block;
        color: $corPrice;
        background-color: $corF8;
        width: 100%;
        max-height: 64px;
        padding: 13px;
        text-align: center;
        font: {
          size: 21px;
          weight: 900;
        }
      }
    }
  }
}

.listMarks{
    padding: 3px;
    overflow: visible;
    min-height: 55px;
    margin: 1em auto;
    text-align: center;
    a{
      cursor: pointer;
    }
    img{
      margin: 0 7px;
      width: 60px;
      filter: grayscale(1) ;
      &:hover{
        filter: grayscale(0)
      }
    }
  }

.page-item{
  .page-link{
    color: $cor00
  }
  &.active{
    .page-link{
      background-color: $cor00;
      border-color: $cor00;
    }
  }
}

.net{
  &.nav-link {
    background-color: $bgNetIcon;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    .fab{
      color: $corFF;
      &:hover{
        color: $corFF;
      }
    }
  }
  @include redes-over;
}

.carousel{
  &__image{
    background-position: center;
  }
  .gallery{
    position: relative;
    &-next, &-prev{
      top: 0;
      width: 30px;
      height: 100%;
      opacity: 0.6;
      font-size: 30px;
      position: absolute;
      &:focus{ outline: none }
      &:hover{ opacity: 1 }
      background: $bgBtnsGallery;
      background: linear-gradient(0deg,$corFF00 15%, $corFF10 25%, $corFF50 100%)
    }
    &-next{
      right: 0;
    }
    &-title{
      position: absolute;
      top: 0;
      height: 2.5em;
      width: 100%;
      background: transparent;
      background: linear-gradient(0deg, $gray10 0%, $gray50 50%, #ecedf2 99%)
    }
    &-price{
      bottom: 0;
      margin: 0;
      width: 100%;
      line-height: 1.1em;
      padding: 0.3em 0 0.8em 0;
      text-shadow: 1px 2px 3px $cor0050;
      font: {
        size: 21px;
        weight: 700;
      }
      text-align: center;
      position: absolute;
      color: $corFF;
      background: $cor00;
      background: linear-gradient(180deg, $cor0010 0%, $cor0050 25%, $bgFooter 75%);
    }
    &-over{
      &::after{
        content: " ";
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top:0
      }
    }
  }
}

.privacidade{
  padding: 0 1em;
  p{
    text-indent: 2em;
    text-align: justify;

  }
  h2{
    font-size: 1.5em;
    margin-bottom: 0.5em ;
  }
}
//>>>>> DETALHES <<<<<<<
.info{
  &.topUp{
    top:-2em;
    width:100%;
    position: relative;
    margin: {
      right: auto;
      left: auto;
    };
  }
  &-cover{
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $corFF50;
    z-index: 2;
    border-radius: 1em;
  }
  &-modelo, &-contato{
    position: relative;
    background-color: $corFF;
    padding: 2em 3.5em;
    border-radius: 1em;
    margin: 0 1em 2em 0;
  }

  &-contato{
    padding: 2em;
  }

  &-title{
    color: $corAA;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 0.8em;
    b{
      color: $cor00
    }
    small{
      color: $cor77;
      font-size: 14px;
      line-height: 0;
    }
  }

  &-items{
    line-height: 2em;
    label{
      margin: 0;
    }
    p{
      font-size: 16px;
      font-weight: 600;
    }
  }

  &-checked{
    display: inline-block;
    margin-bottom: 0.5em;
    p{
      margin-bottom: 0;
      &::before{
        content: '\f00c';
        font-family: 'Font Awesome 5 Free';
        font-size: 0.8em;
        color: $corAA;
        margin-right: 0.5em;
      }
    }
  }
  &-list{
    display: flex;
    flex-wrap: wrap;
  }

  &-preco{
    color: $corAA;
    text-align: right;
    b{
      color: $corPrice;
      font-size: 38px
    }
  }
}
//-------- FIM ---------
.quemsomos{
  &-mainphoto{
    padding-bottom: 41%;
    background-size: cover;
  }
}
// >> correção do DROPDOWN da página de detalhes
.w-100.dropdown{
  .btn,
  .dropdown-menu.show{width: 100%;}
  .dropdown-toggle::after{display: none;}
}


// >>>>>>>>> ADM <<<<<<<<<<
body{
  .adm{
    &-btn{
      background-color: $cor00;
      margin: 0!important;
      &:hover{
        background-color: $cor0050;
      }
    }
    .MuiInput-input{
      &[type='number']{
        text-align: center;
      }
    }
    .nav-pills .nav-link:not(.active):hover{
      color: $cor00;
      font-weight: 600;
    }
    .brand{
      &-image{
        position: relative;
        &-i::after{
          position: absolute;
          background: $corFF;
          height: 30%;
          width: 67%;
          right: 2px;
          bottom: -.7em;
          transform: rotate(-20deg);
          font-size: 7px;
          text-align: right;
          padding: 2px;
          font-family: Arial;
          font-weight: 900;
          content: "NET";
        }
      }
    }
    .social{
      .MuiSwitch-thumb{
        margin: -10px auto auto -10px ;
      }
      .MuiSwitch-switchBase{
        color: red;
        & + .MuiSwitch-track{
          background-color: red
        }
        &.Mui-checked{
          color: green;
          & + .MuiSwitch-track{
            background-color: green
          }
        }
      }
      .groupFieldsKey{
        width: calc(100% - 130px);
        margin-right: 10px;
        display: inline-block;
      }
      &.banners{
        .groupFieldsKey{
          width: calc(100% - 70px);
        }
      }
    }
    .nav-link.active{
      p, i{
        color:  $corFF;
      }
      background-color: $cor00!important;
    }
  }
}

// >>>>>>>>> CORE UI <<<<<<<<<<
.MuiStepper-vertical{
  padding: 1em 0!important;
}

.MuiCard-root{margin: 10px;}
.MuiCard-root .MuiButtonBase-root{margin: 0!important;}

.MuiButtonBase-root{
  margin: 10px!important;
  &.btn-success{
    background-color: $bgCorSuccess;
    color: $corFF;
    &:hover{
      background-color: $overCorSuccess;
    }
    .MuiTouchRipple-root{
      display: none;
    }
  }
  
  &.btn-danger{
    background-color: $bgCorDanger;
    color: $corFF;
    &:hover{
      background-color: $overCorDanger;
    }
    .MuiTouchRipple-root{
      display: none;
    }
  }

  &.btn-dark{
    &:hover{
      color: $cor00;
    }
    .MuiTouchRipple-root{
      display: none;
    }
  }
}
.MuiStepIcon-root.MuiStepIcon-completed,
.MuiStepIcon-root.MuiStepIcon-active{
  color: $overCorDanger!important;
}
.MuiButton-root.Mui-disabled{
  display: none;
}
body{
  .MuiInput-underline:after{
    border-bottom: 2px solid $bgFooter;
  }

  .MuiFormLabel-root{
    color: $corBB;
    &.Mui-focused{
      color: $bgFooter;
    }
  }
  .MuiFormControl-root{
    width: 100%;
  }
  .MuiChip-clickable, .MuiChip-clickable{
    & svg{
      fill: white
    }
  }
  .MuiChip-clickable:hover, .MuiChip-clickable:focus{
    background-color: $bgOverChip;
    color: $corFF;
    .MuiChip-avatar{
        color:  $corOverAvatar;
        background:  $bgOverAvatar;
    }
    & svg{
      fill: black
    }
  }
  .modal-header{
    background-color: $bgModalHeader;
  }
  .MuiChip-root {
    .MuiChip-avatar{
      color: $corFF;
      font-size: 14px;
      font-weight: 700;
      background-color: $corOverAvatar;
    }
    &:hover{
      .MuiAvatar{
        &-root{
          cursor: pointer;
          color:  $corOverAvatar;
          background:  $bgOverAvatar;
        }
      }
    }
  }
  .adm{
    .ribbon-wrapper {
      top:8px;
      right: 13px;
      width: 70px;
      height:70px
    }
  }
  .fullscreen{
    z-index: 9999;
    position: fixed;
  }
}
// ----------------------------
body .MuiAutocomplete-endAdornment{
  top: calc(50% - 30px);
}
.form-search{
  .form-control:focus,
  .select__control.select__control--is-focused{
    border-color: $boxBorderCor;
    box-shadow: 0 0 0 1px $boxBorderCor;
  }
}
.modal-dialog .info-modelo{
  padding: 1.5em;
}
.financiamento{
  background-color: $corFF;
  padding: 2em;
  border-radius: 1em;
}
.footer{
  color: $corFooter;
  background-color: $bgFooter;
  hr{
    border-color: $corFooter;
  }
  a{
    text-decoration: none;
    color: inherit;
    font-weight: 700;
  }
  .nav{
    justify-content: center;
    a{
      & > i.fab{
        color: $bgFooter;
      }
      &:hover > i {
        color: $corFF;
      }
      @include redes-over;
    }
  }
  .mapLocation{
    display: inline-block;
    min-width: 200px;
    &::before{
      content: '\f3c5';
      float: right;
      font-size: 2em;
      margin-left: 10px;
      padding-top: 3px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 700;
    }
  }
}

.waButton{
  button{
    border-width: 0;
    background-color: transparent!important;
    &::after{
      display: none;
    }
    &:hover, &:focus{
      box-shadow: none!important;
    }
  }
}
@media (max-width: 480px){
  .info-modelo{
    padding: 1.5em;
  }
  .layout-navbar-fixed .wrapper.adm {
    .content-wrapper{
      padding-left: 1.5em !important;
    }
    form{
      margin-right: 1.5em !important;
    }
}
}
@media (max-width: 815px){
  .form-search{
    margin-top: -1em;
  }
  .carousel-indicators{
    display: none!important;
  }
  .info.topUp{
    top:-2em;
  }
  .info-contato, .info-modelo{
    margin: 0 0 2em 0;
  }
}